import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import ReactPlayer from 'react-player/lazy';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Title from '../components/Title';

import '../css/project-template.scss';

const ComponentName = ({ data }) => {
  const {
    title,
    description,
    image,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    videourl,
    stack,
    url,
    slug,
    content,
    clientUrl,
    image2Text,
    image3Text,
    image4Text,
    image5Text,
    image6Text,
    image7Text,
  } = data.project;

  const clientUrlClean =
    clientUrl &&
    clientUrl
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0];

  const urlClean =
    url &&
    url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];

  return (
    <Layout>
      <SEO
        title={`${title} | Alex Policastro Project`}
        description={description}
        url={`/projects/${slug}`}
        imageSeo={image.publicURL}
      />
      <section className="project-template" role="main">
        <div className="container">
          <article className="project-content">
            <div className="top-info">
              <Title title={title} subTitle={description} h1 />
              <ul className="project-stack">
                {stack.map(item => {
                  return <li key={item.id}>{item.title}</li>;
                })}
              </ul>
              {(clientUrl || url) && (
                <div className="project-links">
                  {clientUrl && (
                    <a href={clientUrl}>{clientUrlClean}</a>
                  )}
                  {url && <a href={url}>{urlClean}</a>}
                </div>
              )}
            </div>
            {image && (
              <div
                className=""
                data-sal="fade"
                data-sal-duration="600"
                data-sal-delay="10"
                data-sal-easing="easeInOutSine"
              >
                <Image
                  fluid={image.childImageSharp.fluid}
                  className="project-img"
                  alt={title}
                  fadeIn
                  durationFadeIn={500}
                />
              </div>
            )}

            {content && (
              <div
                className="content"
                data-sal-duration="600"
                data-sal-delay="10"
                data-sal-easing="easeInOutSine"
              >
                <ReactMarkdown source={content} />
              </div>
            )}
            {url && (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="btn center-btn"
                data-sal-duration="600"
                data-sal-delay="10"
                data-sal-easing="easeInOutSine"
              >
                view project website
              </a>
            )}

            {videourl && (
              <div
                className="player-wrapper"
                data-sal-duration="600"
                data-sal-delay="10"
                data-sal-easing="easeInOutSine"
              >
                <ReactPlayer
                  url={videourl}
                  width="100%"
                  height="100%"
                  controls
                  //  playing={!!thumbnail && !!hideThumbnail}
                  volume={1}
                  className="react-player"
                  aria-label="video"
                />
              </div>
            )}
            <div
              className={classNames({
                'project-images': true,
              })}
            >
              {image2 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image2.childImageSharp.fluid}
                    className="project-img"
                    alt={!image2Text ? 'image 2' : image2Text}
                  />
                  <p>{image2Text}</p>
                </div>
              )}
              {image3 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image3.childImageSharp.fluid}
                    className="project-img"
                    alt={!image3Text ? 'image 3' : image3Text}
                  />
                  <p>{image3Text}</p>
                </div>
              )}
              {image4 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image4.childImageSharp.fluid}
                    className="project-img"
                    alt={!image4Text ? 'image 4' : image4Text}
                  />
                  <p>{image4Text}</p>
                </div>
              )}
              {image5 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image5.childImageSharp.fluid}
                    className="project-img"
                    alt={!image5Text ? 'image 5' : image5Text}
                  />
                  <p>{image5Text}</p>
                </div>
              )}
              {image6 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image6.childImageSharp.fluid}
                    className="project-img"
                    alt={!image6Text ? 'image 6' : image6Text}
                  />
                  <p>{image6Text}</p>
                </div>
              )}
              {image7 && (
                <div
                  className="project-img-wrapper"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="10"
                  data-sal-easing="easeInOutSine"
                >
                  <Image
                    fluid={image7.childImageSharp.fluid}
                    className="project-img"
                    alt={!image7Text ? 'image 7' : image7Text}
                  />
                  <p>{image7Text}</p>
                </div>
              )}
            </div>
          </article>
        </div>
      </section>
    </Layout>
  );
};

ComponentName.propTypes = {
  data: PropTypes.shape({}),
};

ComponentName.defaultProps = {
  data: [],
};
export const query = graphql`
  query GetSingleProject($slug: String) {
    project: strapiProjects(slug: { eq: $slug }) {
      title
      description
      url
      clientUrl
      slug
      videourl
      content
      image2Text
      image3Text
      image4Text
      image5Text
      image6Text
      stack {
        id
        title
      }
      image {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default ComponentName;
